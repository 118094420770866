import React from "react";
import Img from "gatsby-image";
import { Grid, Row, Col } from "react-styled-flexboxgrid";
import { graphql, StaticQuery } from "gatsby";
import styled from "styled-components";
import BackgroundImage from "gatsby-background-image";
import Link from "gatsby-link";
import { Primary, Alternate } from "../../components/Section";
import { styleBackgroundImage } from "../../components/Section/section.style";
import { ButtonGhost } from "../../components/Button";

const BackgroundSection = ({ className, children }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "hero-hands-meditate.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={(data) => {
      // Set ImageData.
      const imageData = data.desktop.childImageSharp.fluid;
      return (
        <BackgroundImage
          Tag="section"
          className={className}
          fluid={imageData}
          backgroundColor={`#040e18`}
        >
          {children}
        </BackgroundImage>
      );
    }}
  />
);

const StyledBackgroundSection = styled(BackgroundSection)`
  ${styleBackgroundImage};
`;

const GlobalStrategyList = styled.ol`
  text-align: left;
`;
const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.global.colors.greenApple};
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
`;

const Philosophy = ({ data }) => (
  <section>
    <StyledBackgroundSection centered>
      <Grid>
        <Row>
          <Col xs={12}>
            <h1>Our Philosophy</h1>
            <h3>TRUTH - COURAGE - MORAL CHARACTER</h3>
            <p>
              The MJMD Global Group is committed to providing clients a pathway
              towards “Creating a New History.” Truth, courage and moral
              character make up the foundational platform of MJ Global. Our
              portfolio of companies build from this foundation while
              representing some of the most innovative and up and coming brands
              in the United States.
            </p>
          </Col>
        </Row>
      </Grid>
    </StyledBackgroundSection>
    <Primary centered>
      <Row>
        <Col xs={12}>
          <h2>GLOBAL STRATEGY</h2>
          <p>
            Dr. Michael J has been able to lay a foundation for starting an
            incredible unique and diversified group of financially strong
            businesses. Each of the MJMD Global Group’s companies have committed
            to making new history.
          </p>
          <p>We have 8 strategic areas of focus:</p>
          <GlobalStrategyList>
            <li>
              <StyledLink to="/touchless">Tableware</StyledLink> Product
              innovation and distribution.
            </li>
            <li>
              Management of{" "}
              <StyledLink to="/hand-surgery">Hand Surgical services</StyledLink>{" "}
              emphasizing a free market medical approach of transparency.
            </li>

            <li>
              <StyledLink to="/mediation">
                Mediation Management & Coaching
              </StyledLink>{" "}
              for athletes, healthcare professionals, and the LGBT community
              going through divorce.
            </li>
            <li>Management and investment of medical properties.</li>
            <li>
              Management of Thoroughbred{" "}
              <StyledLink to="/horse-racing">horse racing</StyledLink>{" "}
              partnerships.
            </li>
            <li>
              Management of{" "}
              <StyledLink to="/real-estate">
                Turtle Property Management Company.
              </StyledLink>
            </li>
            <li>
              Management of MJ MD Voice LLC:{" "}
              <StyledLink to="/voice">Voice Acting services</StyledLink>.
            </li>
            <li>
              Fashion development and design in{" "}
              <StyledLink to="/fashion">golf attire and formal wear</StyledLink>{" "}
              for men with global taste.
            </li>
          </GlobalStrategyList>
        </Col>
      </Row>
    </Primary>

    <Alternate>
      <Row>
        <Col xs={12} md={6}>
          <h2>Global Growth</h2>
          <blockquote>
            “I believe that we cannot live better than in seeking to become
            better ….” -Socrates
          </blockquote>
          <p>
            Whether in the operating room, in the board room or on a pier in
            Malibu, California protecting marine life by using new innovative
            biodegradable flatware, we at MJMD Global Group seek to have our
            companies and products become better. We strive to grow the
            companies that we manage. Read about the creation of Touchless
            Tableware.
          </p>
          <p>Read more about Free Market Medicine at The Joyner Hand.</p>
          <ButtonGhost
            onClick={() => (window.location = "//thejoynerhand.com/")}
          >
            Free Market Medicine
          </ButtonGhost>
        </Col>
        <Col xs={12} md={6}>
          <Img fluid={data.socrates.childImageSharp.fluid} alt="socrates" />
        </Col>
      </Row>
    </Alternate>
  </section>
);

export default Philosophy;
