import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";

import Philosophy from "../components/Philosophy";

const PhilosophyPage = ({ data }) => (
  <Layout>
    <Philosophy data={data} />
  </Layout>
);

export default PhilosophyPage;

export const query = graphql`
  query {
    socrates: file(relativePath: { eq: "socrates.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`;
